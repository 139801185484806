import NewReleasesIcon from '@mui/icons-material/NewReleases'
import { Paper, Link, Stack } from '@mui/material'
import { Link as Glink } from 'gatsby'
import React from 'react'

const NewFeatureAlert = ({ link, text, isFooter = false }) => (
  <Paper
    sx={{
      width: isFooter ? 'fit-content' : '100%',
      bgcolor: '#e3f2fd',
      p: 1,
      mx: isFooter ? 'auto' : 0,
    }}
  >
    <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
      <NewReleasesIcon sx={{ color: '#0d47a1' }} />
      <span style={{ color: '#0d47a1' }}>Nowa funkcja!</span>
      <Link
        href={link}
        component={Glink}
        sx={{
          color: '#0d47a1',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
      >
        {text}
      </Link>
    </Stack>
  </Paper>
)

export default NewFeatureAlert
